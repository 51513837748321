<template>
 <v-app >
    <app-menu/>

    <div class="text-center">
      <v-dialog v-model="isLoad" persistent width="300">
        <v-card color="primary" dark >
          <v-card-text>
            cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card> 
      </v-dialog>
    </div> 

    <v-app-bar color="deep" dark > 
      <v-spacer></v-spacer> 
      <v-text-field @keyup.enter.native="search" v-model="filter" dense flat hide-details rounded solo-inverted ></v-text-field>  
      <v-btn icon  @click="search"><v-icon>mdi-magnify</v-icon> </v-btn>  
      <div v-if="!isMobile()"> 
        <v-container fluid>
          <v-row align="center">   
            <v-col>
              <v-select style="width:150px;height:40px" v-model="orden" @change="applyFilter()"
                :items="['Descripción','Menor precio', 'Mayor precio', 'Más vendido']" 
                label="Ordenar por" >
              </v-select>
            </v-col> 
          </v-row>
        </v-container>   
      </div>
      <v-spacer></v-spacer> 
    </v-app-bar> 
    <v-app-bar color="deep" dark v-if="isMobile()">  
      <v-container>
        <v-row>
          <v-col>
            <v-btn @click="showFilter('filter')" >
              <img src="../../public/filtro.svg" width="15px" style="margin:5px" />filtrar
            </v-btn>
          </v-col>
          <v-col> 
              <v-select style="width:150px;height:40px" v-model="orden" @change="applyFilter()"
                :items="['Descripción','Menor precio', 'Mayor precio', 'Más vendido']" 
                label="Ordenar por" >
              </v-select>
          </v-col>  
        </v-row>
      </v-container> 
    </v-app-bar> 

    <v-navigation-drawer v-model="sceemFiltro" absolute temporary width="300px" class="blue-grey lighten-5 height-app" app>   
        <v-list nav dense >
          <!-- Inicio filtro CELULAR-->
          <!-- <v-list-item-group> -->
              <div v-if="typeScreem=='filter'">
                <v-list-item class="px-2">
                  <v-list-item-title  @click.stop="sceemFiltro = !sceemFiltro">
                    <h1>Filtrar por</h1>
                  </v-list-item-title> 
                  <v-btn icon  @click.stop="sceemFiltro = !sceemFiltro"> 
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item>
                <v-list-item> 
                    <v-list-item>
                        <v-container class="px-0" fluid>
                          <v-switch @change="filterOnlystock()" v-model="onlystock" label="Solo producto con existencia." ></v-switch>
                        </v-container> 
                    </v-list-item> 
                </v-list-item>   
                
                <v-list-item-title> 
                  <v-card flat color="transparent">
                    <v-list-item-title>Precio</v-list-item-title> 
                    <v-card-text>
                      <v-row>
                        <v-col class="px-4">
                          {{formatMXN(range[0])}}  -- {{formatMXN(range[1])}}
                          <v-range-slider v-model="range" @change="movementPriceFilter()"  :max="max" :min="min" hide-details class="align-center" >
                            <template v-slot:prepend>
                              <v-text-field  style="display:none;" :value="range[0]"  type="number" @change="$set(range, 0, $event)"></v-text-field>
                            </template>
                            <template v-slot:append style="">
                              <v-text-field  style="display:none;" :value="range[1]"  type="number" @change="$set(range, 1, $event)"></v-text-field>
                            </template>
                          </v-range-slider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>  
                </v-list-item-title> 
                <!-- <v-list-item>  -->
  
               <v-list>
                <v-list-group  @click="!show_marca?show_marca=true:show_marca=false" :value="show_marca">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>MARCA</v-list-item-title>
                    </v-list-item-content>
                  </template> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title >  
                        <v-checkbox class="my-8"
                        style="width:0.5px;height:0.5px;"
                          v-for="marca in atttibutes.marcas" :key="marca.value"
                                  @change="applyFilter()" v-model="marca.isview" :label="marca.marca"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>

              <v-list>
                <v-list-group @click="!show_intencidad?show_intencidad=true:show_intencidad=false" :value="show_intencidad">
                  <template v-slot:activator >
                    <v-list-item-content>
                      <v-list-item-title >INTENSIDAD POR AROMA</v-list-item-title>
                    </v-list-item-content>
                  </template> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title > 
                        <v-checkbox class="my-8"
                        style="width:0.5px;height:0.5px;"
                        v-for="intencidad in atttibutes.intencidades" :key="intencidad.m_class_intensity_id"
                          @change="applyFilter()" v-model="intencidad.isview" :label="intencidad.intencidad"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>



              <v-list>
                <v-list-group @click="!show_escencia?show_escencia=true:show_escencia=false" :value="show_escencia">
                  <template v-slot:activator >
                    <v-list-item-content>
                      <v-list-item-title >PRODUCTO</v-list-item-title>
                    </v-list-item-content>
                  </template> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> 
                        <v-checkbox class="my-8"
                          style="width:0.5px;height:0.5px;"
                          v-for="escencia in escenciaCollection" :key="escencia.value"
                          @change="applyFilter()" v-model="escencia.isview" :label="escencia.value"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>




              <v-divider class="my-5"></v-divider>
              <div><strong >CLASIFICACIÓN POR AROMA</strong></div>

              <v-list >  
                <v-list-group 
                  v-for="categoria in atttibutes.categorias" 
                  :key="categoria.m_product_category_id"
                  v-model="categoria.is_active"
                  no-action 
                  sub-group  
                >
                  <template v-slot:activator >
                    <v-list-item-content
                      @click="add_category_filter(categoria)"  
                      v-bind:style= "[
                      comprobarExistenciaInArray(filtrosCategorias,categoria.m_product_category_id,'m_product_classification_id')
                      ? {'color':'green'} : {}]"
                    > 
                      <v-list-item-title v-text="categoria.categoria.substring(0,26)"></v-list-item-title>
                    </v-list-item-content>
                  </template>  
                  <v-list-item  @click="add_sub_category(sub_categoria,categoria)" 
                                v-for="sub_categoria in categoria.sub_categorias"
                                :key="sub_categoria.m_product_classification_id">
                    <v-list-item-content
                      v-bind:style= "[
                      comprobarExistenciaInArray(filtrosSubcategoria,sub_categoria.m_product_classification_id,'m_product_classification_id')
                      ? {'color':'#F78B09'} : {}]"
                    >
                      <v-list-item-title v-text="sub_categoria.sub_categoria.substring(0,18) + 
                      (sub_categoria.sub_categoria.length > 18 ? '...':'')
                      "></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>  


              </div>
              <!-- Fin filtro -->
              <v-list-item-title>
                <div v-if="typeScreem=='order'">
                  <v-list-item class="px-2">
                    <v-list-item-title  @click.stop="sceemFiltro = !sceemFiltro">
                      <h1>Ordenar por</h1>
                    </v-list-item-title> 
                    <v-btn icon  @click.stop="sceemFiltro = !sceemFiltro"> 
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item>  
                </div>
              </v-list-item-title> 
          <!-- </v-list-item-group>  -->
        </v-list>
        <br><br><br><br><br>
        <div class="my-10"></div>
    </v-navigation-drawer>  
    <div style="min-heigth:40px;">
      <!-- LUIS AGUILERA-->
      <!-- <app-slider v-if="verfiltro == false && page == 1" /> -->
      <app-slider/> 
    </div>
    <template class="grey lighten-2"  >



<!-- Este es el inicio del content -->
      <v-row class="grey lighten-2 justify-center" heigth v-if="isLoad==false">  
        <!-- <v-col cols="12" sm="1"></v-col>  -->
        
        <!-- Inicio filtro Escritorio-->
        <v-col cols="12" sm="3" v-if="isLoad==false"  > 
            <!-- <v-row>
            <v-col  cols="12" sm="1"></v-col>
            <v-col  cols="12" sm="10"> -->
          <v-list nav dense v-if="!isMobile()" class="my-5" style="margin-left:30px" max-width="400">
              <h2>Filtrar por</h2>

              <div class="my-2">
                <v-btn
                  color="secondary"
                  dark
                  block
                  @click="clean_localStorage()"
                >
                  Reiniciar Búsqueda
                </v-btn>
              </div>

              <v-container class="px-0" fluid>
                <v-switch @change="filterOnlystock()" v-model="onlystock" label="Solo producto con existencia." ></v-switch>
              </v-container>   
              <v-card flat color="transparent">
                <br>
                <div><strong>Precio</strong></div>
                <v-card-text>
                  <v-row>
                    <v-col class="px-4">
                      {{formatMXN(range[0])}}  -- {{formatMXN(range[1])}}
                      <v-range-slider v-model="range" @change="movementPriceFilter()"  :max="max" :min="min" hide-details class="align-center" >
                        <template v-slot:prepend>
                          <v-text-field  style="display:none;" :value="range[0]"  type="number" @change="$set(range, 0, $event)"></v-text-field>
                        </template>
                        <template v-slot:append style="">
                          <v-text-field  style="display:none;" :value="range[1]"  type="number" @change="$set(range, 1, $event)"></v-text-field>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <br> 

              <v-list>
                <v-list-group  @click="!show_marca?show_marca=true:show_marca=false" :value="show_marca">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>MARCA</v-list-item-title>
                    </v-list-item-content>
                  </template> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title >  
                        <v-checkbox class="my-8"
                        style="width:0.5px;height:0.5px;"
                          v-for="marca in atttibutes.marcas" :key="marca.value"
                                  @change="applyFilter()" v-model="marca.isview" :label="marca.marca"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>

              <v-list>
                <v-list-group @click="!show_intencidad?show_intencidad=true:show_intencidad=false" :value="show_intencidad">
                  <template v-slot:activator >
                    <v-list-item-content>
                      <v-list-item-title >INTENSIDAD POR AROMA</v-list-item-title>
                    </v-list-item-content>
                  </template> 
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title > 
                        <v-checkbox class="my-8"
                        style="width:0.5px;height:0.5px;"
                        v-for="intencidad in atttibutes.intencidades" :key="intencidad.m_class_intensity_id"
                          @change="applyFilter()" v-model="intencidad.isview" :label="intencidad.intencidad"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            

              <v-list>
                <v-list-group @click="!show_escencia?show_escencia=true:show_escencia=false" :value="show_escencia">
                  <template v-slot:activator >
                    <v-list-item-content>
                      <v-list-item-title >PRODUCTO</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> 
                        <v-checkbox class="my-8"
                          style="width:0.5px;height:0.5px;"
                          v-for="escencia in escenciaCollection" :key="escencia.value"
                          @change="applyFilter()" v-model="escencia.isview" :label="escencia.value"></v-checkbox>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>


              <v-divider class="my-5"></v-divider>
              <div><strong >CLASIFICACIÓN POR AROMA</strong></div>

              <v-list >  
                <v-list-group 
                  v-for="categoria in atttibutes.categorias" 
                  :key="categoria.m_product_category_id"
                  v-model="categoria.is_active"
                  no-action 
                  sub-group  
                >
                  <template v-slot:activator >
                    <v-list-item-content
                      @click="add_category_filter(categoria)"  
                      v-bind:style= "[
                      comprobarExistenciaInArray(filtrosCategorias,categoria.m_product_category_id,'m_product_classification_id')
                      ? {'color':'green'} : {}]"
                    > 
                      <v-list-item-title v-text="categoria.categoria.substring(0,15)"></v-list-item-title>
                    </v-list-item-content>
                  </template>  
                  <v-list-item  @click="add_sub_category(sub_categoria,categoria)" 
                                v-for="sub_categoria in categoria.sub_categorias"
                                :key="sub_categoria.m_product_classification_id">
                    <v-list-item-content
                      v-bind:style= "[
                      comprobarExistenciaInArray(filtrosSubcategoria,sub_categoria.m_product_classification_id,'m_product_classification_id')
                      ? {'color':'#F78B09'} : {}]"
                    >
                      <v-list-item-title v-text="sub_categoria.sub_categoria.substring(0,18) + 
                      (sub_categoria.sub_categoria.length > 18 ? '...':'')
                      "></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>  
          </v-list>  
          <!-- </v-col>
        </v-row>  -->
        </v-col> 
        
        <v-col cols="12" sm="7" class="grey lighten-5" v-if="isLoad==false">  
          <v-container class="grey lighten-5"  > 
          
            <v-chip class="ma-2"  v-for="categoria in filtrosCategorias" :key="categoria.m_product_category_id" 
              close color="primary" outlined @click:close="delete_categoria(categoria)">
              {{categoria.categoria}}
            </v-chip>

            <v-chip class="ma-2" v-for="sub_categoria in filtrosSubcategoria" :key="sub_categoria.m_product_classification_id" 
              close color="orange" label outlined  @click:close="delete_sub_categoria(sub_categoria.m_product_classification_id)">
              {{sub_categoria.sub_categoria}}
            </v-chip>
            <br>
            <v-row>
                <v-col>
                  <p class="text-h6" v-if="verfiltro == true && valorBuscado != ''" style="font-size: 3em;color :#909090">  
                    <strong>{{valorBuscado}}</strong> 
                    <v-btn text color="error" @click="deleteFilter">X</v-btn>
                  </p>
                  <v-card class="container text-center grey lighten-5" max-width="200" style="color:black" elevation="0"> 
                    <strong>{{productos.length}}</strong>  Resultados
                    <v-divider color="cyan"></v-divider>
                    </v-card>
                </v-col>
                <v-col cols md="4" class="text-center" >
                  <v-select
                      :items="[9,18,27,36,45]"
                      outlined
                      label="Número de Productos a Mostrar"
                      dense
                      @change="paginator()"
                      v-model="totalPage" 
                  ></v-select>            
                </v-col>    
            </v-row>
          </v-container> 

          <v-row class="mb-6" > 

            <v-col v-for="producto in productosPaginator" :key="producto.value">  
                                        <!-- {{producto}} -->

              <v-hover>
                <template v-slot:default="{ hover }" >
                    <a style="text-decoration: none;" :href="'/shop/Product/' + producto.value + '/home/' + page"> 
                      <v-card style=" border-bottom: 2px solid red" :elevation="hover ? 24 : 2" class="mx-auto my-3" width="260" height="auto">
                          <!-- width="260" height="500" @click="seeProduct(producto.value)"> -->
                        <center>   
                            <v-img :src="`${ubicacionImagenProducto}${producto.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                                    aspect-ratio="1" class="grey lighten-2" width="220" height="170">  
                              <v-row justify="end" class="fill-height ma-0" v-if="producto.monto_descuento != null">
                                <v-avatar color="#02DE7D" size="70" >
                                  <span class="white--text">
                                      {{producto.descuento_name}}
                                      <div class="white--text" style="font-size: 0.7em;">
                                      descuento
                                      </div>
                                  </span> 
                                </v-avatar>
                              </v-row>
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                </v-row>
                              </template> 
                            </v-img>  
                          <div class="text-decoration-line-through" style="color: green;margin-bottom:-25px" v-if="producto.monto_descuento != null">
                              Antes {{formatMXN((producto.costo_original))}}
                          </div>
                        </center>  
                        <v-container class="mx-auto" style=""> 
                          <v-card class="container" elevation="0">  
                            <div>{{producto.name.substring(0,64)}}</div>  
                            <div class="my-3 subtitle-2" >{{cut_text(producto.categoria,9)}} : {{cut_text(producto.sub_categoria,11)}}</div> 
                            <div class="my-3 subtitle-2">Presentación: {{producto.presentacion}}</div> 
                            <div class="my-3 subtitle-2">Intensidad : {{producto.intencidad}}</div>
                            <div class="my-3 subtitle-2">Código : {{producto.value}}</div>
                          </v-card>
                        </v-container>
                        <v-divider class="mx-4">Costos</v-divider>
                        <v-card-actions> 
                          <v-chip class="ma-1"  color="pink" label text-color="white"> 
                            <div v-if="producto.monto_descuento != null">
                              Ahora {{ formatMXN(producto.l0) }}
                            </div>
                            <div v-else>
                              {{ formatMXN(producto.l0) }}
                            </div>
                          </v-chip>  
                          <p class="font-weight-light my-2" style="font-size: 0.9em;">
                            {{ producto.mex_quantytotal }} {{producto.mex_quantytotal==1?' Disponible':' Disponibles'}}
                          </p>
                          <!-- <v-chip  class="ma-1" color="pink" label text-color="white">
                            {{ producto.mex_quantytotal }} {{producto.mex_quantytotal==1?' Disponible':' Disponibles'}}
                          </v-chip>  -->
                        </v-card-actions> 
                      </v-card> 
                    </a> 
                </template>
              </v-hover> 
            </v-col> 
          </v-row>   
          <div class="text-center">
            <v-pagination 
              v-on:input="paginator" 
              v-model="page" 
              :length="lengthPaginator"  
              circle
              :total-visible="7"
              ></v-pagination>
          </div>  
          <br> 
        </v-col>
        <v-col cols="9" sm="2" v-if="isMobile() && isLoad==false && verPromos == true" >
              <v-card elevation="15" class="container">
                <v-btn fab x-small color="pink" @click="verPromos=false" ><v-icon>mdi-close</v-icon></v-btn>
                  <v-alert class="my-2 text-center">
                     <h2 style="color:orange;">
                      <strong>¡ NUEVOS PRODUCTOS !</strong>
                    </h2> 
                  </v-alert>
                <v-virtual-scroll item-height="350" height="1100" :items="productoNuevo">
                      <template v-slot:default="{item}">
                        <v-alert width="100%" color="cyan" border="left"  elevation="1" colored-border> 
                          <a style="text-decoration: none;" :href="'/shop/Product/' + item.value + '/home/' + page"> 
                            <div>
                              <center>   
                                <v-img width="60%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                                      :lazy-src="`../../public/noImg.png`"
                                        aspect-ratio="1" class="grey lighten-2"> 
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                                    </v-row>
                                  </template> 
                                </v-img>
                              </center>
                              <v-card-text>
                                <div class="my-3 subtitle-2">{{item.name.substring(0,83)}}</div> 
                              </v-card-text>  
                            </div>
                          </a>
                        </v-alert>
                      </template>
                      
                  </v-virtual-scroll>
              </v-card> 
        </v-col> 
        <v-col cols="12" sm="2" v-if="!isMobile() && isLoad==false && verPromos == true" >
               <v-card  class="my-5 container" style="margin-right:10px" elevation="15">
                  <v-btn fab x-small color="pink" @click="verPromos=false" ><v-icon>mdi-close</v-icon></v-btn>
                  <v-alert class="my-2 text-center">
                     <h2 style="color:orange;">
                      <strong>¡ NUEVOS PRODUCTOS !</strong>
                    </h2> 
                  </v-alert>
                  <v-virtual-scroll class="mx-auto text-center justify-center" item-height="350" height="1100" :items="productoNuevo">
                      <template v-slot:default="{item}">
                        <center>
                          <v-alert width="100%" max-width="197" color="cyan" border="left"  elevation="3" colored-border> 
                              <a style="text-decoration: none;" :href="'/shop/Product/' + item.value + '/home/' + page"> 
                                <div>
                                  <center>   
                                    <v-img width="80%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                                          :lazy-src="`../../public/noImg.png`"
                                            aspect-ratio="1" class="grey lighten-2"> 
                                      <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                          <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                                        </v-row>
                                      </template> 
                                    </v-img>
                                  </center>
                                  <v-card-text>
                                    <div  v-snip="1" class="my-2 subtitle-2">{{item.name}}</div> 
                                  </v-card-text>  
                                </div>
                              </a>
                          </v-alert>
                        </center>
                      </template>
                  </v-virtual-scroll>
                  <!-- <v-col  v-for="producto in productoPrincipal" :key="producto.value">  
                    
                    <center>  
                    <v-alert width="100%" color="cyan" border="left"  elevation="1" colored-border> 
             
                          <a style="text-decoration: none;" :href="'/shop/Product/' + producto.value + '/home/' + page"> 
                            <div>
                              <center>   
                                <v-img width="40%" :src="`https://refividrio.com.mx/imgdis/${producto.value}.jpg`" 
                                      :lazy-src="`../../public/noImg.png`"
                                        aspect-ratio="1" class="grey lighten-2"> 
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                    </v-row>
                                  </template> 
                                </v-img>
                              </center>
                              <v-card-text>
                                <div class="my-4 subtitle-3">{{producto.name.substring(0,83)}}</div> 
                              </v-card-text>  
                            </div>
                          </a>
                    </v-alert>
                    </center>
                </v-col> -->
              </v-card> 
          </v-col>
          <!-- <v-col cols="12" sm="1"></v-col> -->
        </v-row>

        <v-container v-else>
          <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
            <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
            <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
        </v-container>
    </template> 
  </v-app> 
</template>  
 
<script>
import AppSlider from '../components/Slider'; 
import config from '../json/config.json'
import axios from 'axios'; 
import AppMenu from '../components/Menu.vue';

export default {
  name: "Productos", 
  props: ['pagina'], 
  data() {
    return {
      ubicacionImagenProducto:'https://amoresens.com.mx/distribuidor/productos/',
      verPromos : true,
      productosOferta : [],
      ordenMenorP : false,
      ordenMayorP : false,
      ordenMasVendido : false, 
      orden : 'Descripción',
      isfilterAndalucia:true,
      isfilterLD:true,
      onlystock : false,
      page: 1,
      filter : "",

      min: 0,
      max: 5000,
      range: [0, 5000],
      maxProd : 0,
      minProd : 0,
      // isMobile : null,
      sceemFiltro : false,
      typeScreem:null,
      productos : [],
      productosPaginator : [],
      lengthPaginator:0,
      totalPage:9,
      isLogged : false,
      user : {},
      isLoad : false,
      valorBuscado : "",
      verfiltro : false,
      productosFiltrados : [],
      atttibutes : {marcas:[],categorias:[],intencidades:[],presentaciones:[]},
      filtrosCategorias : [],
      filtrosSubcategoria : [],
      escenciaCollection:[],
      show_escencia : false,
      show_intencidad : false,
      show_marca : false,
      productoNuevo:[]
    }; 
  }, 
  components: { 
    'app-slider': AppSlider,
    'app-menu': AppMenu, 
  }, 
  mounted(){
    this.ProductosNuevos();
  },
  async created() {
    try{
      if (parseInt(this.pagina) > 0) {
        this.page = parseInt(this.pagina);
      }else{
        this.page = 1;
      }
    }catch (ex){
      this.page = 1;
    }
    this.isLoad = true; 
    await this.getattibutes();
    await this.validaLogin();
    // await this.allProduct(); 
    
    // if(this.recoverParams()){
    //   this.applyFilter();
    // }
    
    // await this.applyFilter();
    // this.getExtremos();

    if (localStorage.getItem("datos") === null) {
        await this.applyFilter();
        this.getExtremos();
        // console.log("entro");

    }else{

        await this.allProductv2();
        this.getExtremos();
        // console.log("entro2");
        await this.recuperarMarcaAtributo();
        await this.recuperarIntensidadAtributo();
        await this.recuperarCategoriaAtributo();
        await this.recuperarSubCategoriaAtributo();
        //console.log(this.atttibutes.intencidades);

    }
    
    this.productosOferta = [];
    for (let index = 0; index < this.productos.length; index++) {
      let element = this.productos[index];  
      if (element.value == 'P15UN0978' || element.value == 'P15UN0979' || element.value == 'P15UN1122' || element.value == 'P15UN1123') {
          this.productosOferta.push(element);
      } 
    } 
    await this.paginator(); 
    this.verfiltro = false;
    this.page = 1;
    this.isLoad = false;  
  } 
  ,methods: {

      clean_localStorage(){ 
        //localStorage.removeItem('datos');
        //localStorage.clear();

        window.localStorage.clear(); //clear all localstorage
        window.localStorage.removeItem("datos"); //remove one item
 

        this.filter = '';
        this.onlystock = false;
        //this.range =  '';
        //this.range = [this.min, this.max];
        this.ordenMenorP = false;
        this.ordenMayorP = false;
        this.ordenMasVendido = false;
        this.atttibutes.marcas = [];
        this.atttibutes.intencidades = [];
        this.filtrosCategorias = [];
        this.filtrosSubcategoria = [];        
        //this.getattibutes();
        
        this.applyFilter();
        this.getExtremos();
        this.deleteFilter();

       location.reload();

    },   

    cut_text(text,i){
      // producto.sub_categoria
      let resturn_text = "";
      if (text == null) {
        return "";
      }
      try {
        resturn_text = text.substring(0,i);
      } catch (error) {
        // console.log(error);
      }
      return resturn_text;
    },
    add_category_filter(categoria){ 
      if (categoria.sub_categorias.length == 0) {
        this.add_category(categoria,true);
      } 
    },
    delete_sub_categoria(m_product_classification_id){ 
      if (this.comprobarExistenciaInArray(this.filtrosSubcategoria,m_product_classification_id,'m_product_classification_id')){
        let filtros = [];
        for (let index = 0; index < this.filtrosSubcategoria.length; index++) { 
          if (this.filtrosSubcategoria[index].m_product_classification_id != m_product_classification_id) {
            filtros.push(this.filtrosSubcategoria[index]); 
          }
        }
        this.filtrosSubcategoria = filtros;
        this.applyFilter();
      }
    },
    delete_categoria(categoria){ 
      if (this.comprobarExistenciaInArray(this.filtrosCategorias,categoria.m_product_category_id,'m_product_category_id')){
        for (let index = 0; index < categoria.sub_categorias.length; index++) {
          this.delete_sub_categoria(categoria.sub_categorias[index].m_product_classification_id);
        }
        let filtros = []; 
        for (let index = 0; index < this.filtrosCategorias.length; index++) { 
          if (this.filtrosCategorias[index].m_product_category_id != categoria.m_product_category_id) {
            filtros.push(this.filtrosCategorias[index]); 
          }
        }  
        this.filtrosCategorias = filtros;
        this.applyFilter();
      } 
    },
    add_category(categoria,apllifilter){
      // console.log("EEEEEEEEEEEe");
      // console.log(categoria);
      // console.log(apllifilter);
      if (!this.comprobarExistenciaInArray(
        this.filtrosCategorias
        ,categoria.m_product_category_id
        ,'m_product_category_id')) {
        this.filtrosCategorias.push(categoria); 
        if (apllifilter) {
          this.applyFilter(); 
        }
      }
    },
    add_sub_category(sub_categoria,categoria){  
      this.add_category(categoria,false);
      if (!this.comprobarExistenciaInArray(
        this.filtrosSubcategoria
      ,sub_categoria.m_product_classification_id
      ,'m_product_classification_id')){
        this.filtrosSubcategoria.push(sub_categoria);
        this.applyFilter();
      }
    },
    comprobarExistenciaInArray(array,value,field){
      let respuesta = false;
      for (let index = 0; index < array.length; index++) {
        const element = array[index]; 
        if (element[field] == value) {
          respuesta = true;
        }
      } 
      return respuesta;
    },
    async getattibutes(){
      this.requestattributes_marcas();
      this.requestattributes_intencidad();
      this.requestattributes_presentacion();  
      this.requestattributes_categoria();  
      this.request_esencia();
    }, 
    async requestattributes(types,m_p_cat_id){
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:m_p_cat_id}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        resource = resource.data;
      }else{
        resource = [];
      }
      return resource;
    },
    async requestattributes_marcas(){
      let types = 'marcas';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.marcas = resource.data;
      }else{
        this.atttibutes.marcas = [];
      } 
    },
    async requestattributes_categoria(){
      let types = 'categoria';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.categorias = resource.data;
        for (let index = 0; index < this.atttibutes.categorias.length; index++) {
          let res = await this.requestattributes('sub_categoria' 
          ,this.atttibutes.categorias[index].m_product_category_id)
          this.atttibutes.categorias[index].sub_categorias = res;
        }
      }else{
        this.atttibutes.categorias = [];
      } 
    },
    async requestattributes_intencidad(){
      let types = 'intencidad';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.intencidades = resource.data;
      }else{
        this.atttibutes.intencidades = [];
      } 
    },
    

    async requestattributes_presentacion(){
      let types = 'presentacion';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.presentaciones = resource.data;
      }else{
        this.atttibutes.presentaciones = [];
      } 
    },

    async request_esencia(){
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getesencia", 
      {'headers': { 'token': this.$cookie.get('token') },params:{}})
      .then(res=>{return res.data;})
      .catch(err=>{return err;});
      if (resource.status == "success") {
        this.escenciaCollection = resource.data;
      }else{
        this.escenciaCollection = [];
      } 
    },
    // recoverParams(){ 
    //   if (this.$route.query.isValid != undefined) {
    //     try {
    //       this.filter = this.$route.query.filter;
    //       this.onlystock = this.$route.query.onlystock=='true'?true:false; 
    //       if (this.$route.query.range != undefined) { 
    //         this.range = this.$route.query.range;
    //       } 
    //       this.isfilterAndalucia = this.$route.query.isfilterAndalucia=='true'?true:false;
    //       this.isfilterLD = this.$route.query.isfilterLD=='true'?true:false;
    //       this.ordenMenorP =  this.$route.query.ordenMenorP=='true'?true:false;
    //       this.ordenMayorP = this.$route.query.ordenMayorP=='true'?true:false;
    //       this.ordenMasVendido = this.$route.query.ordenMasVendido=='true'?true:false;
    //       this.orden = this.$route.query.orden; 
    //       return true;
    //     } catch (error) { 
    //       console.log(error);
    //       return false;
    //     }
    //   }else{
    //     return false;
    //   }
    // },
    getExtremos(){
      for (let index = 0; index < this.productos.length; index++) {
        const element = this.productos[index]; 
        if (index == 0) {
            this.min = element.l0;
            this.max = element.l0; 
        }
        if (parseFloat(this.max) < parseFloat(element.l0)){
          this.max = parseInt(element.l0);
        }
        if (parseFloat(element.l0) < parseFloat(this.min))
        { 
          this.min = parseInt(element.l0);
        }
      }  
      this.range = [this.min, this.max]; 
    },

    async applyFilter(){
      this.ordenMenorP = false;
      this.ordenMayorP = false;
      this.ordenMasVendido = false;
      switch (this.orden) {
        case 'Menor precio':
          this.ordenMenorP = true;
          break;
        case 'Mayor precio':
          this.ordenMayorP = true;
          break;
        case 'Más vendido':
          this.ordenMasVendido = true;
          break;    
      }
      this.isLoad = true;
      await this.allProduct();
      this.page = 1;
      await this.paginator();
      this.verfiltro = true;
      this.sceemFiltro = false;
      this.isLoad = false;
    },
    movementPriceFilter(){
      this.applyFilter();
    },
    async filterOnlystock(){
      this.applyFilter();
    },
    showFilter(value){ 
      this.typeScreem = value;
      this.sceemFiltro = true;
    },
    isMobile(){
      if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)){
          return true;
        }else{
          return false;
        }
    },
    async deleteFilter(){
      this.page = 1;
      this.isLoad = true;  
      this.filter = "";
      this.valorBuscado = "";
      this.verfiltro = false;
      await this.allProduct();
      await this.paginator();
      this.isLoad = false;  
    },
    async search(){   
      this.isLoad = true;  
      this.valorBuscado = this.filter.toUpperCase();
      await this.allProduct();
      this.page = 1;
      await this.paginator();
      this.verfiltro = true;
      this.isLoad = false;  
    },
    async validaLogin(){
         this.user = await axios.get(config.apiAmoresens + "/user/userByToken", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});    
        if (this.user.status == "success") {
          this.user = this.user.user; 
          this.isLogged = true;
        }else if(this.user.status == "unauthorized"){ 
          this.isLogged = false;
        }  
    }
    ,agrupacion_isview(array,fieldconcat,isFilter){
      let cadenaRetorno = "";
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (isFilter) {
          if (element.isview) {
            cadenaRetorno += element[fieldconcat] + ",";
          }
        }else{
          cadenaRetorno += element[fieldconcat] + ",";
        }
      }
      cadenaRetorno += "#";
      cadenaRetorno = cadenaRetorno.replace(',#','');
      return cadenaRetorno;
    }

    ,async allProduct(){
      this.productos = [];
      this.productosPaginator = [];

      let empresasValidas = this.agrupacion_isview(this.atttibutes.marcas,'m_product_group_id',true);
      let intencidadesValidas = this.agrupacion_isview(this.atttibutes.intencidades,'m_class_intensity_id',true);
   
      let categorias_group_v = this.agrupacion_isview(this.filtrosCategorias,'m_product_category_id',false);
      let sub_categorias_group_v = this.agrupacion_isview(this.filtrosSubcategoria,'m_product_classification_id',false);

      let escencia_group = this.agrupacion_isview(this.escenciaCollection,'m_class_essences_id',true);
      let uri = config.apiAmoresens + "/productos/all";

      // console.log("A1");
      // console.log(this.atttibutes.intencidades);

      const paramsv2 = {
            filter: this.filter
            ,onliStock : this.onlystock
            ,range : this.range
            ,marcasfiltradas : empresasValidas
            ,ordenMenorP : this.ordenMenorP
            ,ordenMayorP : this.ordenMayorP
            ,ordenMasVendido : this.ordenMasVendido  
            ,intencidadesfiltradas : intencidadesValidas
            ,categorias_group : categorias_group_v
            ,sub_categorias_group : sub_categorias_group_v
            ,escencia_group : escencia_group
        };


      this.productos = await axios.get(uri
      ,{
        headers: { 'token': this.$cookie.get('token') },
        params: paramsv2
      

      }).then(function (response) { 
        if (response.data.status == "success") {
          return response.data.data;
        } else {
          // console.log(response.data.data);
          return [];
        }
      }).catch(function (response){ 
        console.log(response);
        return [];
      });

        // Guardo el objeto como un string
      localStorage.setItem('datos', JSON.stringify(paramsv2));
      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage); 
    },


    async recuperarMarcaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionMarcas = this.atttibutes.marcas;
      let boleano_marcas = true;

      // console.log('GENERAR ARRAYS');
      const filtroMarcas = memoriaCache.marcasfiltradas;
      let dato_split = filtroMarcas.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO');
      let nuevaColeccionMarcas = dato_split;
      for (var i=0; i<nuevaColeccionMarcas.length; i++) {
              // console.log('Datos Obtenidos Marcav1');
              // console.log(nuevaColeccionMarcas[i]);


      // console.log('INICIA MODIFICACION ARRAY');
        coleccionMarcas.map(function(datovM){
                        
                    // console.log('Datos Obtenidos Marcav2');
                    // console.log(nuevaColeccionMarcas[i]);

                    if(nuevaColeccionMarcas[i] == "#"){
                          datovM.isview = true;
                    }else{
                          datovM.isview = false;

                          if(datovM.m_product_group_id == nuevaColeccionMarcas[i]){
                            nuevaColeccionMarcas.shift();
                            datovM.isview = boleano_marcas;
                          }else{
                            datovM.isview = false;
                          }    

                    }
                

          // console.log(datovM);
          return datovM;
        });

      // console.log('TERMINA MODIFICACION ARRAY');

       }

    },

    async recuperarIntensidadAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionIntensidades = this.atttibutes.intencidades;
      let boleano_esencias = true;

      // console.log('GENERAR ARRAYS');
      const filtroIntensidad = memoriaCache.intencidadesfiltradas
      let dato_split = filtroIntensidad.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO');
      let nuevaColeccionIntensidad = dato_split;
      for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
              // console.log('Datos Obtenidosv2');
              // console.log(nuevaColeccionIntensidad[i]);

      // console.log('INICIA MODIFICACION ARRAY');
        coleccionIntensidades.map(function(datov2){
                        
                    // console.log('Datos Obtenidosv2');
                    // console.log(nuevaColeccionIntensidad[i]);

                      if(nuevaColeccionIntensidad[i] == "#"){
                        datov2.isview = true;
                      }else{
                        datov2.isview = false;
                      }

                      if(datov2.m_class_intensity_id == nuevaColeccionIntensidad[i]){
                        nuevaColeccionIntensidad.shift();
                        datov2.isview = boleano_esencias;
                      }else{
                        datov2.isview = false;
                      }

          // console.log(datov2);
          return datov2;
        });
      // console.log('TERMINA MODIFICACION ARRAY');

       }       
    },

    async recuperarCategoriaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionCategoria = this.atttibutes.categorias;
      let boleano_categoria = true;

      // console.log('GENERAR ARRAYS CATEGORIA');
      const filtroCategoria = memoriaCache.categorias_group;
      let dato_split = filtroCategoria.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS CATEGORIA');
      let nuevaColeccionCategoria = dato_split;
      // for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
      //         console.log('Datos Obtenidos Intendasdasdasdsidad');
      //         console.log(nuevaColeccionIntensidad[i]);
      //  }

      // console.log('INICIA MODIFICACION CATEGORIA');
        coleccionCategoria.map(function(dato){
                        
            for (var i=0; i<nuevaColeccionCategoria.length; i++) {
                    // console.log('Datos Obtenidos Categoria');
                    // console.log(nuevaColeccionCategoria[i]);

                    if(nuevaColeccionCategoria[i] == "#"){
                          dato.isview = true;
                    }else{
                          dato.isview = false;

                          if(dato.categorias_group == nuevaColeccionCategoria[i]){
                            dato.isview = boleano_categoria;
                            nuevaColeccionCategoria.shift();

                          }else{
                            dato.isview = false;
                          }

                    }

            }
          // console.log(dato);
          return dato;
        });

      // console.log('TERMINA MODIFICACION ARRAY CATEGORIA');
       
    },


      async recuperarSubCategoriaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionSubCategoria = this.filtrosSubcategoria;
      let boleano_subcategoria = true;

      // console.log('GENERAR ARRAYS SUBCATEGORIA');
      const filtroSubCategoria = memoriaCache.sub_categorias_group;
      let dato_split = filtroSubCategoria.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO SUBCATEGORIA');
      let nuevaColeccionSubCategoria = dato_split;
      // for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
      //         console.log('Datos Obtenidos Intendasdasdasdsidad');
      //         console.log(nuevaColeccionIntensidad[i]);
      //  }

      // console.log('INICIA MODIFICACION ARRAY SUBCATEGORIA');
        coleccionSubCategoria.map(function(dato){
                        
            for (var i=0; i<nuevaColeccionSubCategoria.length; i++) {
                    // console.log('Datos Obtenidos SubCategoria');
                    // console.log(nuevaColeccionSubCategoria[i]);

                    if(nuevaColeccionSubCategoria[i] == "#"){
                          dato.isview = true;

                    }else{
                          dato.isview = false;

                          if(dato.sub_categorias_group == nuevaColeccionSubCategoria[i]){
                            dato.isview = boleano_subcategoria;
                            nuevaColeccionSubCategoria.shift();
                          }else{
                            dato.isview = false;
                          }

                    }

            }
          // console.log(dato);
          return dato;
        });

      // console.log('TERMINA MODIFICACION ARRAY SUBCATEGORIA');
       
    },

    async allProductv2(){

        // Se parsea para poder ser usado en js con JSON.parse :)
        var array = JSON.parse( localStorage.getItem('datos') );

        this.filter = array.filter;
        this.onlystock = array.onliStock;
        //this.range = array.range;

        this.ordenMenorP = array.ordenMenorP;
        this.ordenMayorP = array.ordenMayorP;
        this.ordenMasVendido = array.ordenMasVendido;

        // this.filtrosCategorias = [array.categorias_group];

        // console.log(array);
        // console.log(array.filter);
        // console.log(array.onliStock);
        // console.log(array.range);
        // console.log('MARCAS: ' + array.marcasfiltradas);
        // console.log(array.ordenMenorP);
        // console.log(array.ordenMayorP);
        // console.log(array.ordenMasVendido);
        // console.log('INTENSIDAD: ' + array.intencidadesfiltradas);
        // console.log('CATEGORIAS: ' + array.categorias_group);
        // console.log('SUBCATEGORIAS: ' + array.sub_categorias_group);
        // console.log(array.escencia_group);

      this.productos = [];
      this.productosPaginator = [];

      let empresasValidas = array.marcasfiltradas;
      let intencidadesValidas = array.intencidadesfiltradas;

      let categorias_group_v =  array.categorias_group;
      let sub_categorias_group_v = array.sub_categorias_group;

      // let escencia_group = this.agrupacion_isview(this.escenciaCollection,'m_class_essences_id',true);

      // console.log("A2");
      // console.log(intencidadesValidas);

      let uri = config.apiAmoresens + "/productos/all";

      const paramsv2 = {
            filter: this.filter
            ,onliStock : this.onlystock
            //,range : this.range
            ,marcasfiltradas : empresasValidas
            ,ordenMenorP : this.ordenMenorP
            ,ordenMayorP : this.ordenMayorP
            ,ordenMasVendido : this.ordenMasVendido  
            ,intencidadesfiltradas : intencidadesValidas
            ,categorias_group : categorias_group_v
            ,sub_categorias_group : sub_categorias_group_v
            //,escencia_group : escencia_group
        };

      this.productos = await axios.get(uri
      ,{
        headers: { 'token': this.$cookie.get('token') },
        params: paramsv2
      
      }).then(function (response) { 
        if (response.data.status == "success") {
          // console.log(response.data);
          return response.data.data;
        } else {
          // console.log(response.data.data);
          return [];
        }
      }).catch(function (response){ 
        console.log(response);
        return [];
      });

      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage); 
    }


    ,async paginator(){
      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage);
      this.isLoad = true; 
      this.productosPaginator = [];
      let fin = ((this.page * (this.totalPage )) -1) > (this.productos.length -1) ? (this.productos.length -1):((this.page * (this.totalPage )) -1);  
    
      for (let index = ( ( (this.page -1) * this.totalPage)); index <= fin; index++) {
        let element = this.productos[index];    
        this.productosPaginator.push(element);
        if (index == fin) {
          this.isLoad = false;
          window.scrollTo(0,0);
        }
      } 
      window.scrollTo(0,0);
    },formatMXN(value) {
      var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
      // return formatter.format(value);
      return `${formatter.format(value)} MXN`;
    },
    // ,seeProduct(value){   
      // console.log(value);
      // this.$router.push(`/shop/Product/${value}/home/${this.page}`);
    // },
    async ProductosNuevos(){ //Productos nuevos
      await axios.get(config.apiAmoresens + '/productos/ProductosNuevos')
      .then(respuesta=>{
        this.productoNuevo = respuesta.data.data;
      }).catch(err=>{return err;})
    }
  },
  
}
</script>
