<template>
  <!-- <v-carousel  v-model="indexSlider" hide-delimiters height="100%"> 
    <v-carousel-item v-for="(item,i) in imgs" :key="i"  >
        <v-img contain :src="item" height="100%" width="100%"></v-img>
    </v-carousel-item>
  </v-carousel>  -->
  <v-carousel  show-arrows-on-hover v-model="indexSlider"  height="100%"> 
    <v-carousel-item v-for="(item,i) in sliderImg2" :key="i"  >
        <v-img contain :src="item.files" height="100%" width="100%" max-height="357"></v-img>
    </v-carousel-item>
  </v-carousel> 
</template>

<script> 
  import axios from 'axios';
  import config from '../json/config.json'
  export default {
    data: () => ({
      indexSlider: 0,
      sliderImg2:[],
      // imgs: [
      //   '../flyers2108/10.jpg', 
      //   '../flyers2108/11.jpg',
      //   //'../flyers2108/9.png', 
      //   '../flyers2108/1.jpeg', 
      //   '../flyers2108/2.jpeg', 
      //   '../flyers2108/3.jpeg', 
      //   // '../flyers2108/4.jpeg', 
      //   // '../flyers2108/5.jpeg', 
      //   // '../flyers2108/6.jpeg', 
      //   //'../flyers2108/7.jpeg', 
      //   //'../flyers2108/8.jpeg', 

      //   '../catalogoHome.jpg', 
      //   '../bannerbolsas2.jpeg', 
      //   '../andalucia.webp',
      //   '../ld.webp',
      // ],
    }),
    methods:{
      // changeSlider(){
      //   if (this.indexSlider == this.imgs.length-1) {
      //     this.indexSlider = 0; 
      //   }else{
      //     this.indexSlider ++;
      //   }  
      // }
      async mostrarimagenes() {
          let fechaHoy = new Date().toISOString().substr(0, 24);
          // console.log('---',fechaHoy);
          await axios.get(config.apiAmoresens + "/img/get_slider",
          )
          .then(res=>{
              this.sliderImg2 = res.data.data;
          })
          .catch(err=>{return err;});
            //::::::::: Validar fechas y actualizar estatus.
          for (var i = 0; i <  this.sliderImg2.length; i++) {
            this.total= this.sliderImg2[i].validarFecha;
            this.total2= this.sliderImg2[i];
            // console.log('TOTAL',this.total);
            // console.log(this.total2);
            this.total3 = this.sliderImg2[i].fechaFinal;
            if(this.total == true){
              if (fechaHoy >=  this.total3) { //::::::::::fecha de hoy y fecha final
                  // console.log('Este se actualizará..',this.sliderImg2[i]);
                  await axios.put(config.apiAmoresens + '/img/validarFecha',this.total2)
                  .then(res =>{return res.data.data})
                  .catch(err =>{return err})      
                      
              }else{
                console.log('FECHA A TIEMPO');
              }
            }
          }
      },
      changeSlider() {
        if (this.indexSlider == this.sliderImg2.length - 0) {
          this.indexSlider = 1;
        } else {
          this.indexSlider++;
        }
      },
    },
    created(){
      setInterval(this.changeSlider, 10000);
      this.mostrarimagenes();
      

    }
  }
</script>
